// AboutMe.jsx

import React, { useState } from "react";
import styled, { keyframes, createGlobalStyle } from "styled-components";
import { motion } from "framer-motion";
import {
  FaLinkedin,
  FaEnvelope,
  FaJs,
  FaPython,
  FaAws,
  FaReact,
  FaNodeJs,
  FaJava,
  FaCode,
  FaDatabase,
  FaGlobe,
  FaBriefcase,
  FaTools,
  FaPhp, // New Import
  FaHtml5, // New Import
  FaCss3, // New Import
  FaToolbox, // New Import
  FaRaspberryPi, // New Import
} from "react-icons/fa";
import {
  SiMongodb,
  SiDotnet,
  SiAzuredevops,
  SiExpress,
  SiDocker,
  SiGit,
  SiFlutter,
  SiTypescript,
  SiAngular,
  SiPostgresql,
  SiTensorflow, // New Import
} from "react-icons/si";

import profilePic from "./profile.jpg";
import projectImage1 from "./projects/joblinker/project1.png";
import projectImage2 from "./projects/joblinker/project2.png";
import projectImage3 from "./projects/joblinker/project3.png";
import uniconnectImage1 from "./projects/uniconnect/uniconnect1.png";
import uniconnectImage2 from "./projects/uniconnect/uniconnect2.png";
import objectDetectionImage1 from "./projects/objectdetection/obj1.png";
import objectDetectionImage2 from "./projects/objectdetection/obj2.png";
import periodicImage1 from "./projects/periodic/periodic1.jpg";
import periodicImage2 from "./projects/periodic/periodic2.jpg";
import pwnImage1 from "./projects/pwn/pwn1.png";
import pwnImage2 from "./projects/pwn/pwn2.png";
import freelanceImage1 from "./projects/freelance/freelance1.jpg";
import freelanceImage2 from "./projects/freelance/freelance2.jpg";
import vempiImage1 from "./projects/vempi/vempi1.jpg";
import vempiImage2 from "./projects/vempi/vempi2.jpg";

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
`;

const scroll = keyframes`
  0%,
  20% {
    opacity: 0;
    transform: translate(-50%, 0);
  }
  50% {
    opacity: 1;
    transform: translate(-50%, 15px);
  }
  80%,
  100% {
    opacity: 0;
    transform: translate(-50%, 30px);
  }
`;

function AboutMe() {
  const iconSize = 50;

  // State for the modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");

  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setModalImageSrc("");
    setIsModalOpen(false);
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Header>
          <Nav>
            <NavLink href="#skills">Skills</NavLink>
            <NavLink href="#experience">Experience</NavLink>
            <NavLink href="#projects">Projects</NavLink>
            <NavLink href="#contact">Contact</NavLink>
          </Nav>
        </Header>

        {/* Introduction Section */}
        <Introduction>
          <BackgroundOverlay />
          <Content>
            <ProfileImg src={profilePic} alt="Profile" />
            <IntroTitle
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              Axel Yalmaz
            </IntroTitle>
            <IntroSubtitle
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 1.5, duration: 1 }}
            >
              <Highlight>Software Developer</Highlight> and{" "}
              <Highlight>Freelancer</Highlight> with a degree from the
              University of Birmingham.
            </IntroSubtitle>
            <IntroDescription
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 2.5, duration: 1 }}
            >
              Transforming complex challenges into innovative software solutions. Specializing in full-stack development, cloud computing, and cybersecurity to drive efficiency and security.
            </IntroDescription>
            <CallToAction
              href="#contact"
              initial={{ opacity: 0, y: 20 }} // Start hidden and slightly offscreen
              animate={{ opacity: 1, y: 0 }} // Animate to visible and in place
              transition={{ delay: 3.5, duration: 1 }} // Adjust the delay to ensure it starts after other animations
            >
              Let's Connect
            </CallToAction>
          </Content>

        </Introduction>

        {/* Skills Section */}
        <Skills id="skills">
          <SkillsWrapper
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          >
            <SectionTitle>Skills</SectionTitle>

            {/* Organize into categories */}
            <SkillsGrid>
              <Category>
                <CategoryTitle>
                  <FaCode /> Languages
                </CategoryTitle>
                <SkillsContainer>
                  {[
                    {
                      icon: <FaJs size={iconSize} color="#F0DB4F" />,
                      name: "JavaScript",
                    },
                    {
                      icon: <SiTypescript size={iconSize} color="#3178C6" />,
                      name: "TypeScript",
                    },
                    {
                      icon: <FaPython size={iconSize} color="#3776AB" />,
                      name: "Python",
                    },
                    {
                      icon: <FaCode size={iconSize} color="#239120" />,
                      name: "C#",
                    },
                    {
                      icon: <FaJava size={iconSize} color="#007396" />,
                      name: "Java",
                    },
                  ].map((skill) => (
                    <SkillItem key={skill.name}>
                      {skill.icon}
                      <SkillName>{skill.name}</SkillName>
                    </SkillItem>
                  ))}
                </SkillsContainer>
              </Category>

              <Category>
                <CategoryTitle>
                  <FaTools /> Frameworks
                </CategoryTitle>
                <SkillsContainer>
                  {[
                    {
                      icon: <FaReact size={iconSize} color="#61DAFB" />,
                      name: "React",
                    },
                    {
                      icon: <SiAngular size={iconSize} color="#DD0031" />,
                      name: "Angular",
                    },
                    {
                      icon: <FaNodeJs size={iconSize} color="#68A063" />,
                      name: "Node.js",
                    },
                    {
                      icon: <SiExpress size={iconSize} color="#000000" />,
                      name: "Express.js",
                    },
                    {
                      icon: <SiDotnet size={iconSize} color="#512BD4" />,
                      name: ".NET",
                    },
                    {
                      icon: <SiFlutter size={iconSize} color="#02569B" />,
                      name: "Flutter",
                    },
                  ].map((skill) => (
                    <SkillItem key={skill.name}>
                      {skill.icon}
                      <SkillName>{skill.name}</SkillName>
                    </SkillItem>
                  ))}
                </SkillsContainer>
              </Category>

              <Category>
                <CategoryTitle>
                  <FaDatabase /> Databases & Cloud
                </CategoryTitle>
                <SkillsContainer>
                  {[
                    {
                      icon: <FaDatabase size={iconSize} color="#4169E1" />,
                      name: "SQL",
                    },
                    {
                      icon: <SiPostgresql size={iconSize} color="#336791" />,
                      name: "PostgreSQL",
                    },
                    {
                      icon: <SiMongodb size={iconSize} color="#47A248" />,
                      name: "MongoDB",
                    },
                    {
                      icon: <FaAws size={iconSize} color="#FF9900" />,
                      name: "AWS",
                    },
                    {
                      icon: <SiAzuredevops size={iconSize} color="#0089D6" />,
                      name: "Azure",
                    },
                  ].map((skill) => (
                    <SkillItem key={skill.name}>
                      {skill.icon}
                      <SkillName>{skill.name}</SkillName>
                    </SkillItem>
                  ))}
                </SkillsContainer>
              </Category>

              <Category>
                <CategoryTitle>
                  <FaTools /> Tools & Methodologies
                </CategoryTitle>
                <SkillsContainer>
                  {[
                    {
                      icon: <SiDocker size={iconSize} color="#0db7ed" />,
                      name: "Docker",
                    },
                    {
                      icon: <SiGit size={iconSize} color="#F05032" />,
                      name: "Git",
                    },
                    { icon: <SkillIcon>??</SkillIcon>, name: "CI/CD" },
                    {
                      icon: <SkillIcon>??</SkillIcon>,
                      name: "Agile/Kanban",
                    },
                  ].map((skill) => (
                    <SkillItem key={skill.name}>
                      {skill.icon}
                      <SkillName>{skill.name}</SkillName>
                    </SkillItem>
                  ))}
                </SkillsContainer>
              </Category>
            </SkillsGrid>
          </SkillsWrapper>
        </Skills>

        {/* Experience Section */}
<Experience id="experience">
  <ExperienceWrapper>
    <SectionTitle>Experience</SectionTitle>

    <Job>
      <JobHeader>
        <FaBriefcase size={30} color="#00ccff" />
        <JobTitle>Digital Information Systems Intern</JobTitle>
      </JobHeader>
      <Company>SE Controls - Lichfield, UK</Company>
      <JobDuration>June 2023 - September 2023</JobDuration>
      <JobDescription>
        <ul>
          <li>
            Designed and developed an IoT platform using JavaScript, React, and Node.js. Integrated advanced security models and external authentication systems to centralize operations and enhance security across multiple SE Controls locations.
          </li>
          <li>
            Collaborated closely with a team of developers under strict deadlines. Contributed to front-end development, back-end architecture, database design, and the creation of robust security models.
          </li>
          <li>
            Created a comprehensive suite of RESTful APIs to streamline data exchange between IoT devices. Enabled seamless cross-platform integration and ensured efficient handling of concurrent user sessions without performance degradation.
          </li>
        </ul>
      </JobDescription>
    </Job>

    <Job>
      <JobHeader>
        <FaBriefcase size={30} color="#00ccff" />
        <JobTitle>Full Stack Developer</JobTitle>
      </JobHeader>
      <Company>April Circe Limited - London, UK</Company>
      <JobDuration>July 2022 - August 2022</JobDuration>
      <JobDescription>
        <ul>
          <li>
            Launched an innovative micro-learning platform utilizing Bubble.io and Twilio RESTful APIs. Enhanced user engagement and satisfaction, as reflected in positive feedback surveys conducted post-launch.
          </li>
          <li>
            Implemented user dashboards and back-end features to improve functionality and user experience. Coordinated API calls between services to manage data flow and ensure smooth operations.
          </li>
          <li>
            Managed the application database, ensuring data handling adhered to GDPR compliance and was fully secure. Improved key front-end components, increasing the platform�s performance and responsiveness.
          </li>
        </ul>
      </JobDescription>
    </Job>

    <Job>
      <JobHeader>
        <FaBriefcase size={30} color="#00ccff" />
        <JobTitle>Volunteer Full Stack Developer</JobTitle>
      </JobHeader>
      <Company>Mevki Yazilim - Izmir, Turkey</Company>
      <JobDuration>June 2017 - August 2021</JobDuration>
      <JobDescription>
        <ul>
          <li>
            Developed websites using .NET, ASP.NET MVC, C#, and JavaScript for commercial clients, gaining extensive hands-on experience in a real business environment.
          </li>
          <li>
            Created a custom reporting module within the ERP system that enabled real-time data analysis. Improved reporting efficiency and reduced manual reporting errors across departments.
          </li>
          <li>
            Engineered and marketed accounting software using C# and .NET, designed for commercial firms. Deployed the software across multiple businesses to streamline financial operations.
          </li>
        </ul>
      </JobDescription>
    </Job>
  </ExperienceWrapper>
</Experience>


        {/* Projects Section */}
        <Projects id="projects">
          <ProjectsWrapper>
            <SectionTitle>Key Projects</SectionTitle>

            {/* Existing Project */}
            <Project>
              <ProjectTitle>
                <FaGlobe size={30} color="#00ccff" /> JobLinker
              </ProjectTitle>
              <ProjectDuration>September 2023 - April 2024</ProjectDuration>
              <ProjectDescription>
                <p>
                  <strong>JobLinker</strong> is a comprehensive cross-platform
                  job-finding application designed to bridge the gap between
                  employers and job seekers for full-time, part-time, and gig
                  opportunities.
                </p>
                <BulletPoints>
                  <li>
                    <strong>Architecture:</strong> Utilized Flutter (Dart) for
                    front-end development, Node.js (JavaScript) for back-end
                    services, and Python for AI-driven features.
                  </li>
                  <li>
                    <strong>User Authentication:</strong> Implemented secure
                    authentication with JWT, including email verification and
                    password recovery.
                  </li>
                  <li>
                    <strong>Real-time Messaging:</strong> Established WebSocket
                    connections for instant communication between users.
                  </li>
                  <li>
                    <strong>Face Verification System:</strong> Developed an
                    AI-powered face verification system to enhance trust and
                    security among users.
                  </li>
                  <li>
                    <strong>Job Matching Algorithm:</strong> Created an
                    algorithm using Google Maps API for distance calculation and
                    matching based on skills, ratings, and verification status.
                  </li>
                  <li>
                    <strong>Scalable Backend:</strong> Deployed backend services
                    on AWS EC2, utilizing Nginx for secure connections and
                    PostgreSQL for database management.
                  </li>
                  <li>
                    <strong>Multi-platform Compatibility:</strong> Ensured
                    seamless user experience across iOS, Android, Web, Linux,
                    and Windows platforms.
                  </li>
                </BulletPoints>

                <ProjectImagesContainer>
                  <ScreenshotsLabel>Screenshots</ScreenshotsLabel>
                  <ProjectImages>
                    {/* First image with taller height to be bigger */}
                    <JobLinkerImageWrapper
                      onClick={() => openModal(projectImage1)}
                    >
                      <JobLinkerImage
                        src={projectImage1}
                        alt="JobLinker Screenshot 1"
                        className="tall-image"
                      />
                    </JobLinkerImageWrapper>

                    {/* Second and third images with hover effect */}
                    <JobLinkerImageWrapper
                      onClick={() => openModal(projectImage2)}
                    >
                      <JobLinkerImage
                        src={projectImage2}
                        alt="JobLinker Screenshot 2"
                        className="normal-image"
                      />
                    </JobLinkerImageWrapper>

                    <JobLinkerImageWrapper
                      onClick={() => openModal(projectImage3)}
                    >
                      <JobLinkerImage
                        src={projectImage3}
                        alt="JobLinker Screenshot 3"
                        className="normal-image"
                      />
                    </JobLinkerImageWrapper>
                  </ProjectImages>
                </ProjectImagesContainer>

                <TechStackTitle>Technologies Used:</TechStackTitle>
                <TechIcons>
                  <SiFlutter size={iconSize} color="#02569B" title="Flutter" />
                  <FaJs size={iconSize} color="#F0DB4F" title="JavaScript" />
                  <FaPython size={iconSize} color="#3776AB" title="Python" />
                  <FaNodeJs size={iconSize} color="#68A063" title="Node.js" />
                  <FaAws size={iconSize} color="#FF9900" title="AWS" />
                  <FaDatabase
                    size={iconSize}
                    color="#4169E1"
                    title="PostgreSQL"
                  />
                </TechIcons>
              </ProjectDescription>
            </Project>

            <Project>
              <ProjectTitle>
                <FaGlobe size={30} color="#00ccff" /> Vempi Virtual Market
              </ProjectTitle>
              <ProjectDescription>
                <p>
                  Transitioned the company to a fully functional e-commerce
                  platform using PrestaShop, customizing and optimizing the
                  platform to align with the business's unique needs.
                  Spearheaded efforts in various domains, including web design,
                  backend development, SEO, and product management.
                </p>
                <BulletPoints>
                  <li>
                    Customized PrestaShop modules using PHP to extend default
                    functionality, including implementing custom checkout
                    processes and user flows tailored to the business model.
                  </li>
                  <li>
                    Integrated third-party payment systems like PayPal and
                    Stripe, ensuring seamless transactions and providing
                    multiple payment options for users.
                  </li>
                  <li>
                    Managed the hosting environment, including server setup,
                    configuration, and ongoing maintenance, ensuring high
                    availability and security for the e-commerce platform.
                  </li>
                  <li>
                    Handled all product photography, including photo editing and
                    optimization for web display using Photoshop, ensuring
                    high-quality images that enhanced product appeal and drove
                    customer engagement.
                  </li>
                  <li>
                    Redesigned the frontend of the website using HTML5, CSS3,
                    and JavaScript, improving user interface (UI) and user
                    experience (UX), resulting in a more intuitive and visually
                    engaging platform.
                  </li>
                  <li>
                    Optimized the backend for better performance by improving
                    database queries, implementing caching strategies, and
                    enhancing the overall structure to support higher traffic
                    loads.
                  </li>
                  <li>
                    Implemented SEO best practices, such as meta tag
                    optimization, alt text for images, and improved site speed,
                    leading to higher search engine rankings and increased
                    organic traffic.
                  </li>
                  <li>
                    Conducted performance testing and debugging to ensure fast
                    load times and smooth functionality across devices,
                    improving the site's performance by 40%.
                  </li>
                  <li>
                    Integrated a robust product catalog system that allowed easy
                    inventory management and automated updates, streamlining
                    business operations.
                  </li>
                  <li>
                    Provided ongoing training and support to the business
                    owners, ensuring they could efficiently manage the platform
                    and make minor updates without technical intervention.
                  </li>
                </BulletPoints>
                <ProjectImagesContainer>
                  <ScreenshotsLabel>Screenshots</ScreenshotsLabel>
                  <ProjectImages>
                    <ProjectImageWrapper onClick={() => openModal(vempiImage1)}>
                      <ProjectImage
                        src={vempiImage1}
                        alt="Vempi Screenshot 1"
                      />
                    </ProjectImageWrapper>
                    <ProjectImageWrapper onClick={() => openModal(vempiImage2)}>
                      <ProjectImage
                        src={vempiImage2}
                        alt="Vempi Screenshot 2"
                      />
                    </ProjectImageWrapper>
                  </ProjectImages>
                </ProjectImagesContainer>
                <TechStackTitle>Technologies Used:</TechStackTitle>
                <TechIcons>
                  <FaPhp size={iconSize} color="#777BB4" title="PHP" />
                  <FaHtml5 size={iconSize} color="#E34F26" title="HTML" />
                  <FaCss3 size={iconSize} color="#1572B6" title="CSS" />
                  <FaAws size={iconSize} color="#FF9900" title="AWS" />
                </TechIcons>
              </ProjectDescription>
            </Project>

            <Project>
              <ProjectTitle>
                <FaGlobe size={30} color="#00ccff" /> Freelancing (2018 - 2021)
              </ProjectTitle>
              <ProjectDescription>
                <p>
                  Designed websites and custom web applications for commercial
                  firms, providing tailored digital solutions and helping
                  businesses establish a strong online presence.
                </p>
                <BulletPoints>
                  <li>
                    Customized frontend designs using modern HTML5, CSS3, and
                    JavaScript to ensure responsive and visually appealing user
                    interfaces, improving user engagement by 30%.
                  </li>
                  <li>
                    Architected and optimized backend systems with PHP and SQL
                    databases, ensuring secure, scalable, and high-performance
                    solutions for handling client data and transactions.
                  </li>
                  <li>
                    Performed in-depth SEO optimizations, improving search
                    engine rankings and increasing organic traffic for client
                    websites by 25% on average.
                  </li>
                  <li>
                    Managed end-to-end server infrastructure, including setup
                    and configuration of hosting services, domain management,
                    and ensuring optimal website uptime and security.
                  </li>
                  <li>
                    Built custom content management systems (CMS) that allowed
                    clients to easily update their websites without needing
                    technical expertise, reducing maintenance costs by 50%.
                  </li>
                  <li>
                    Collaborated directly with clients from various industries
                    (e.g., retail, consulting, e-commerce) to understand their
                    unique business requirements and deliver tailor-made web
                    solutions that aligned with their goals.
                  </li>
                  <li>
                    Conducted regular performance monitoring and optimization,
                    using caching, code minification, and database tuning
                    techniques to improve site speed and reduce page load times
                    by up to 40%.
                  </li>
                  <li>
                    Provided ongoing support and maintenance services, ensuring
                    websites remained up-to-date with the latest security
                    patches and performance improvements, building long-term
                    client relationships.
                  </li>
                  <li>
                    Worked as a full-stack developer, balancing client
                    expectations with technical constraints to deliver
                    high-quality projects on time and within budget.
                  </li>
                </BulletPoints>
                <ProjectImagesContainer>
                  <ScreenshotsLabel>Screenshots</ScreenshotsLabel>
                  <ProjectImages>
                    <ProjectImageWrapper
                      onClick={() => openModal(freelanceImage1)}
                    >
                      <ProjectImage
                        src={freelanceImage1}
                        alt="Freelancing Screenshot 1"
                      />
                    </ProjectImageWrapper>
                    <ProjectImageWrapper
                      onClick={() => openModal(freelanceImage2)}
                    >
                      <ProjectImage
                        src={freelanceImage2}
                        alt="Freelancing Screenshot 2"
                      />
                    </ProjectImageWrapper>
                  </ProjectImages>
                </ProjectImagesContainer>
                <TechStackTitle>Technologies Used:</TechStackTitle>
                <TechIcons>
                  <FaHtml5 size={iconSize} color="#E34F26" title="HTML" />
                  <FaCss3 size={iconSize} color="#1572B6" title="CSS" />
                  <FaJs size={iconSize} color="#F0DB4F" title="JavaScript" />
                  <FaPhp size={iconSize} color="#777BB4" title="PHP" />
                  <FaDatabase size={iconSize} color="#4169E1" title="SQL" />
                </TechIcons>
              </ProjectDescription>
            </Project>

            {/* New Project: UNICONNECT */}
            <Project>
              <ProjectTitle>
                <FaGlobe size={30} color="#00ccff" /> UNICONNECT
              </ProjectTitle>
              <ProjectDuration>October 2022 - May 2023</ProjectDuration>
              <ProjectDescription>
                <p>
                  <strong>UNICONNECT</strong> is a social networking platform
                  designed to connect university students based on their modules
                  and interests. As the{" "}
                  <Highlight>Software Development Team Leader</Highlight>, I led
                  a team in developing features that facilitate networking,
                  mentorship, and collaboration among students.
                </p>
                <BulletPoints>
                  <li>
                    <strong>Create a Profile:</strong> Implemented a feature
                    allowing users to create detailed profiles including modules
                    taken, year of study, and a personal bio.
                  </li>
                  <li>
                    <strong>Filtered Feeds:</strong> Developed four different
                    feeds�Individuals, Groups, Mentors, and Mentees�to help
                    users discover connections tailored to their needs.
                  </li>
                  <li>
                    <strong>Mentor/Mentee Matching:</strong> Enabled users to
                    select modules for which they seek mentors or mentees,
                    enhancing learning through peer support.
                  </li>
                  <li>
                    <strong>Display Profiles:</strong> Created user cards
                    displaying essential information and a detailed profile view
                    with an option to connect.
                  </li>
                  <li>
                    <strong>Connections List:</strong> Implemented a sidebar to
                    display user connections and access the chat feature.
                  </li>
                  <li>
                    <strong>Chat Feature:</strong> Integrated real-time
                    messaging for connected users to communicate and
                    collaborate.
                  </li>
                </BulletPoints>
                <ProjectImagesContainer>
                  <ScreenshotsLabel>Screenshots</ScreenshotsLabel>
                  <ProjectImages>
                    {[uniconnectImage1, uniconnectImage2].map((src, index) => (
                      <ProjectImageWrapper
                        key={index}
                        onClick={() => openModal(src)}
                      >
                        <ProjectImage
                          src={src}
                          alt={`UNICONNECT Screenshot ${index + 1}`}
                        />
                      </ProjectImageWrapper>
                    ))}
                  </ProjectImages>
                </ProjectImagesContainer>
                <TechStackTitle>Technologies Used:</TechStackTitle>
                <TechIcons>
                  <SiTypescript
                    size={iconSize}
                    color="#3178C6"
                    title="TypeScript"
                  />
                  <SiAngular size={iconSize} color="#DD0031" title="Angular" />
                  <FaNodeJs size={iconSize} color="#68A063" title="Node.js" />
                  <FaJs size={iconSize} color="#F0DB4F" title="JavaScript" />
                  <SiPostgresql
                    size={iconSize}
                    color="#336791"
                    title="PostgreSQL"
                  />
                </TechIcons>
              </ProjectDescription>
            </Project>
            <Project>
              <ProjectTitle>
                <FaGlobe size={30} color="#00ccff" /> PWN Island Hacking Project
              </ProjectTitle>
              <ProjectDescription>
                <p>
                  Worked as part of a team to reverse-engineer and exploit the
                  PWN Island 3 game by injecting custom cheats into the game
                  through advanced memory manipulation techniques. The project
                  required locating key memory addresses using Cheat Engine,
                  modifying game behavior, and integrating a separate game
                  within the existing structure.
                </p>
                <BulletPoints>
                  <li>
                    <strong>Memory Manipulation:</strong> Used Cheat Engine to
                    analyze and find specific memory pointers, which were
                    essential for identifying variables that controlled game
                    elements. By isolating and modifying these memory addresses,
                    we were able to change the game�s behavior dynamically.
                  </li>
                  <li>
                    <strong>Pointer Scanning & Injection:</strong> Applied
                    advanced pointer scanning techniques to track memory
                    locations across game sessions. This allowed us to locate
                    stable addresses for key game components and inject custom
                    C++ code into the game, altering its mechanics.
                  </li>
                  <li>
                    <strong>Game Hacking & Customization:</strong> Successfully
                    injected and integrated the classic "Space Invaders" game
                    within PWN Island 3 by repurposing available game assets and
                    memory space. By modifying game logic, we transformed part
                    of the original game into a playable version of Space
                    Invaders, creating an unexpected and fun Easter egg for
                    users.
                  </li>
                  <li>
                    <strong>Reverse Engineering:</strong> Enhanced
                    reverse-engineering skills through the project by analyzing
                    the game�s memory structure and deciphering the code
                    responsible for rendering graphics and controlling gameplay.
                    This knowledge was used to implement custom cheats and
                    additional features.
                  </li>
                  <li>
                    <strong>Team Collaboration:</strong> Developed teamwork and
                    communication skills by coordinating with teammates to solve
                    complex technical challenges, distribute tasks, and test
                    memory injections without causing game crashes.
                  </li>
                </BulletPoints>

                <GitHubLinkContainer>
                  <GitHubLink
                    href="https://github.com/efesuner12/pwnie-island-hack"
                    target="_blank"
                  >
                    View on GitHub
                  </GitHubLink>
                </GitHubLinkContainer>

                <ProjectImagesContainer>
                  <ScreenshotsLabel>Screenshots</ScreenshotsLabel>
                  <ProjectImages>
                    <ProjectImageWrapper onClick={() => openModal(pwnImage1)}>
                      <ProjectImage
                        src={pwnImage1}
                        alt="PWN Island Screenshot 1"
                      />
                    </ProjectImageWrapper>
                    <ProjectImageWrapper onClick={() => openModal(pwnImage2)}>
                      <ProjectImage
                        src={pwnImage2}
                        alt="PWN Island Screenshot 2"
                      />
                    </ProjectImageWrapper>
                  </ProjectImages>
                </ProjectImagesContainer>

                <TechStackTitle>Technologies Used:</TechStackTitle>
                <TechIcons>
                  <FaCode size={iconSize} color="#00599C" title="C++" />
                  <FaToolbox
                    size={iconSize}
                    color="#FF5733"
                    title="Cheat Engine"
                  />
                </TechIcons>
              </ProjectDescription>
            </Project>

            <Project>
              <ProjectTitle>
                <FaGlobe size={30} color="#00ccff" /> TUBITAK Interactive
                Periodic Table
              </ProjectTitle>
              <ProjectDescription>
                <p>
                  Designed and built an innovative interactive physical periodic
                  table to enhance student engagement with chemistry. The table
                  integrated QR codes with each element, allowing students to
                  scan and access detailed information about the element on a
                  web interface.
                </p>
                <BulletPoints>
                  <li>
                    <strong>Interactive Design:</strong> Transformed a
                    traditional periodic table into an interactive educational
                    tool by assigning a unique QR code to each element. When
                    scanned, the QR codes would redirect to a web page
                    displaying comprehensive data, including atomic properties,
                    uses, and chemical reactions.
                  </li>
                  <li>
                    <strong>Student Engagement:</strong> Developed the project
                    to improve student participation and interaction during
                    chemistry lessons. The periodic table was installed in the
                    classroom, where students could explore each element�s
                    information through their smartphones.
                  </li>
                  <li>
                    <strong>Recognition and Display:</strong> The project was
                    selected for display at school and received recognition from
                    TUBITAK, Turkey's leading research institution, for its
                    innovative approach to enhancing STEM education.
                  </li>
                  <li>
                    <strong>Website Development:</strong> Designed and developed
                    a website where students could view detailed information
                    about each element in real-time, using HTML, CSS, and
                    JavaScript. The site offered dynamic content and multimedia
                    resources, making learning more engaging.
                  </li>
                </BulletPoints>
                <ProjectImagesContainer>
                  <ScreenshotsLabel>Screenshots</ScreenshotsLabel>
                  <ProjectImages>
                    <ProjectImageWrapper
                      onClick={() => openModal(periodicImage1)}
                    >
                      <ProjectImage
                        src={periodicImage1}
                        alt="TUBITAK Screenshot 1"
                      />
                    </ProjectImageWrapper>
                    <ProjectImageWrapper
                      onClick={() => openModal(periodicImage2)}
                    >
                      <ProjectImage
                        src={periodicImage2}
                        alt="TUBITAK Screenshot 2"
                      />
                    </ProjectImageWrapper>
                  </ProjectImages>
                </ProjectImagesContainer>
                <TechStackTitle>Technologies Used:</TechStackTitle>
                <TechIcons>
                  <FaHtml5 size={iconSize} color="#E34F26" title="HTML" />
                  <FaCss3 size={iconSize} color="#1572B6" title="CSS" />
                  <FaJs size={iconSize} color="#F0DB4F" title="JavaScript" />
                </TechIcons>
              </ProjectDescription>
            </Project>

            <Project>
              <ProjectTitle>
                <FaGlobe size={30} color="#00ccff" /> Object Detection for
                Visually Impaired
              </ProjectTitle>
              <ProjectDescription>
                <p>
                  Developed an object detection system using Raspberry Pi and
                  TensorFlow to assist visually impaired individuals by
                  providing real-time audio feedback about their surroundings.
                </p>
                <BulletPoints>
                  <li>
                    <strong>Hardware and Setup:</strong> Worked with Raspberry
                    Pi, setting up and configuring hardware components such as
                    disk storage and camera modules for real-time object
                    detection. Installed and managed Raspberry Pi OS for smooth
                    operation.
                  </li>
                  <li>
                    <strong>Training AI Model:</strong> Successfully trained the
                    model to detect two specific objects using TensorFlow and
                    Python. Used Google's open-source dataset to train the AI to
                    accurately identify objects in various environments.
                  </li>
                  <li>
                    <strong>Object Detection Program Development:</strong>{" "}
                    Developed the object detection program in Python using
                    TensorFlow. The program processed real-time video input from
                    the Raspberry Pi camera and provided audio feedback to the
                    user.
                  </li>
                  <li>
                    <strong>Object Detection Algorithm:</strong> Implemented a
                    real-time detection algorithm capable of recognizing and
                    distinguishing between different objects, ensuring a quick
                    response to the user for ease of navigation.
                  </li>
                  <li>
                    <strong>Voice Feedback Integration:</strong> Integrated
                    text-to-speech libraries that provided spoken feedback to
                    the user, informing them about detected objects in their
                    surroundings.
                  </li>
                </BulletPoints>
                <ProjectImagesContainer>
                  <ScreenshotsLabel>Screenshots</ScreenshotsLabel>
                  <ProjectImages>
                    <ProjectImageWrapper
                      onClick={() => openModal(objectDetectionImage1)}
                    >
                      <ProjectImage
                        src={objectDetectionImage1}
                        alt="Object Detection Screenshot 1"
                      />
                    </ProjectImageWrapper>
                    <ProjectImageWrapper
                      onClick={() => openModal(objectDetectionImage2)}
                    >
                      <ProjectImage
                        src={objectDetectionImage2}
                        alt="Object Detection Screenshot 2"
                      />
                    </ProjectImageWrapper>
                  </ProjectImages>
                </ProjectImagesContainer>
                <TechStackTitle>Technologies Used:</TechStackTitle>
                <TechIcons>
                  <FaPython size={iconSize} color="#3776AB" title="Python" />
                  <SiTensorflow
                    size={iconSize}
                    color="#FF6F00"
                    title="TensorFlow"
                  />
                  <FaRaspberryPi
                    size={iconSize}
                    color="#C51A4A"
                    title="Raspberry Pi"
                  />
                </TechIcons>
              </ProjectDescription>
            </Project>
          </ProjectsWrapper>
        </Projects>

        {/* Image Modal */}
        {isModalOpen && (
          <ModalOverlay onClick={closeModal}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
              <CloseButton onClick={closeModal}>&times;</CloseButton>
              <ModalImage src={modalImageSrc} alt="Project Screenshot" />
            </ModalContent>
          </ModalOverlay>
        )}

        {/* Contact Section */}
        <Contact id="contact">
          <ContactWrapper>
            <SectionTitle>Get in Touch</SectionTitle>
            <ContactInfo>
              <ContactItem href="mailto:axelyalmaz0@hotmail.com">
                <FaEnvelope size={30} color="#e6e6e6" />
                <ContactText>axelyalmaz0@hotmail.com</ContactText>
              </ContactItem>
              <ContactItem href="https://linkedin.com/in/axelyalmaz0">
                <FaLinkedin size={30} color="#0077B5" />
                <ContactText>/in/axelyalmaz0</ContactText>
              </ContactItem>
            </ContactInfo>
          </ContactWrapper>
        </Contact>
      </Container>
    </>
  );
}

export default AboutMe;

// Styled Components

const Container = styled.div`
  font-family: "Poppins", sans-serif;
  background-color: #0d0d0d;
  color: #e6e6e6;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 15px 30px;
  background: #1a1a1a;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px 0; /* Adjust the padding */
    align-items: center; /* Align items to the left */
  }
`;

const GitHubLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
const GitHubLink = styled.a`
  font-size: 1.2rem;
  color: #00ccff;
  text-decoration: none;
  border: 2px solid #00ccff;
  padding: 10px 20px;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #00ccff;
    color: #0d0d0d;
  }
`;

const Logo = styled.a`
  font-size: 2rem;
  color: #00ccff;
  text-decoration: none;
  font-weight: bold;
  margin-right: auto;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;


const Nav = styled.nav`
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 768px) {
    gap: 15px; /* Reduce gap on mobile */
    padding: 0; /* Ensure no padding */
    align-items: center;
  }
`;

const NavLink = styled.a`
  font-size: 1rem;
  color: #e6e6e6;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  padding: 5px 0;

  &:after {
    content: "";
    width: 0%;
    height: 2px;
    background: #00ccff;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: 0.3s;
  }

  &:hover {
    color: #00ccff;
  }

  &:hover:after {
    width: 100%;
  }
`;
const Introduction = styled.section`
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
`;

const BackgroundOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0d0d0d;
  opacity: 0.9;
  z-index: -1;
`;

const Content = styled.div`
  z-index: 1;
`;

const ProfileImg = styled.img`
  width: 180px;
  height: 180px;
  border-radius: 50%;
  margin-bottom: 20px;
  border: 5px solid #00ccff;

  @media (max-width: 480px) {
    width: 140px;
    height: 140px;
  }
`;

const IntroTitle = styled(motion.h1)`
  font-size: 3rem;
  color: #e6e6e6;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const Highlight = styled.span`
  color: #00ccff;
`;

const IntroSubtitle = styled(motion.h2)`
  font-size: 2rem;
  color: #e6e6e6;
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.7rem;
  }

  @media (max-width: 480px) {
    font-size: 1.4rem;
  }
`;

const IntroDescription = styled(motion.p)`
  font-size: 1.2rem;
  color: #e6e6e6;
  max-width: 700px;
  margin: 0 auto 30px;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const CallToAction = styled(motion.a)`
  display: inline-block;
  padding: 15px 30px;
  background-color: #00ccff;
  color: #0d0d0d;
  border-radius: 30px;
  font-size: 1.2rem;
  text-decoration: none;
  margin-top: 20px;
  transition: background-color 0.3s;
  cursor: pointer;

  &:hover {
    background-color: #00b3e6;
  }
`;

const Skills = styled.section`
  padding: 80px 0;
  background-color: #1a1a1a;
  display: flex;
  justify-content: center;
  width: 100%;

  @media (max-width: 1024px) {
    padding: 60px 0;
  }

  @media (max-width: 768px) {
    padding: 40px 0;
  }

  @media (max-width: 480px) {
    padding: 20px 0;
  }
`;


const SkillsWrapper = styled(motion.div)`
  background-color: #262626;
  padding: 40px;
  max-width: 90%; /* Max width remains 90% for responsiveness */
  width: 100%;
  border-radius: 15px;
  transform: scale(1);

  @media (max-width: 1440px) {
    transform: scale(0.95);
  }

  @media (max-width: 1024px) {
    transform: scale(0.9);
  }

  @media (max-width: 768px) {
    transform: scale(0.8);
  }

  @media (max-width: 480px) {
    transform: scale(0.7);
  }
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #00ccff;
  text-align: center;
  margin-bottom: 60px;

  @media (max-width: 1440px) {
    font-size: 2.3rem;
  }

  @media (max-width: 1024px) {
    font-size: 2rem;
  }

  @media (max-width: 768px) {
    font-size: 1.8rem;
    margin-bottom: 30px;
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
    margin-bottom: 20px;
  }
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)); /* Original grid layout */
  gap: 40px;

  @media (max-width: 1500px) {
    display: block; /* Switch to block layout for smaller screens */
  }

  @media (max-width: 1024px) {
    gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust the grid */
  }

  @media (max-width: 768px) {
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr)); /* Narrow the grid */
  }

  @media (max-width: 480px) {
    gap: 15px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Narrow further */
  }
`;

const Category = styled.div`
  text-align: center;

  @media (max-width: 1500px) {
    text-align: center; /* Keep category titles centered */
    margin-bottom: 20px;
  }
`;

const CategoryTitle = styled.h3`
  font-size: 1.8rem;
  color: #e6e6e6;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (min-width: 1500px) {
    font-size: 1.5rem; /* Make the titles smaller after 1500px */
  }

  @media (max-width: 1440px) {
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
    margin-bottom: 15px;
  }
`;

const SkillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the skills horizontally */
  gap: 25px;

  @media (max-width: 1500px) {
    justify-content: center; /* Center the skills for smaller screens */
    gap: 15px;
  }

  @media (max-width: 1024px) {
    gap: 20px;
  }

  @media (max-width: 768px) {
    gap: 15px;
  }

  @media (max-width: 480px) {
    gap: 10px;
  }
`;


const SkillItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333333;
  padding: 20px;
  border-radius: 10px;
  width: 100px;
  transition: transform 0.3s;

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 1500px) {
    padding: 15px;
    width: 80px;
  }

  @media (max-width: 1024px) {
    padding: 15px;
    width: 80px;
  }

  @media (max-width: 768px) {
    padding: 12px;
    width: 70px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    width: 60px;
  }
`;


const SkillName = styled.h4`
  margin-top: 10px;
  font-size: 1rem;
  color: #e6e6e6;

  @media (max-width: 1500px) {
    font-size: 0.9rem;
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }

  @media (max-width: 480px) {
    font-size: 0.7rem;
  }
`;


const SkillIcon = styled.div`
  font-size: 50px;

  @media (max-width: 1500px) {
    font-size: 45px;
  }

  @media (max-width: 1024px) {
    font-size: 40px;
  }

  @media (max-width: 768px) {
    font-size: 35px;
  }

  @media (max-width: 480px) {
    font-size: 30px;
  }
`;


const Experience = styled.section`
  padding: 80px 0;
  background-color: #0d0d0d;
`;

const ExperienceWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
`;

const Job = styled.div`
  margin-bottom: 60px;
`;

const JobHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
`;

const JobTitle = styled.h3`
  font-size: 1.8rem;
  color: #e6e6e6;
`;

const Company = styled.h4`
  font-size: 1.2rem;
  color: #00ccff;
  margin-bottom: 5px;
`;

const JobDuration = styled.p`
  font-size: 1rem;
  color: #999999;
  margin-bottom: 15px;
`;

const JobDescription = styled.div`
  ul {
    list-style: none;
    padding-left: 0;
  }

  li {
    position: relative;
    padding-left: 25px;
    margin-bottom: 15px;
    font-size: 1rem;
    color: #e6e6e6;
    text-align: left;

    &:before {
      content: "�";
      position: absolute;
      left: 0;
      color: #00ccff;
      font-size: 1.5rem;
      line-height: 1;
    }
  }
`;

const Projects = styled.section`
  padding: 80px 0;
  background-color: #1a1a1a;
`;

const ProjectsWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
`;

const Project = styled.div`
  margin-bottom: 60px;
  padding-bottom: 20px; /* Add some padding at the bottom for spacing */
 border-bottom: 2px solid #9370DB ; /* Add an orange line as a separator */

`;

const ProjectTitle = styled.h3`
  font-size: 1.8rem;
  color: #e6e6e6;
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
`;

const ProjectDuration = styled.p`
  font-size: 1rem;
  color: #999999;
  margin-bottom: 15px;
`;

const ProjectDescription = styled.div`
  font-size: 1rem;
  color: #e6e6e6;
  text-align: left;

  p {
    margin-bottom: 15px;
  }
`;

const BulletPoints = styled.ul`
  list-style: disc;
  margin-left: 20px;
  margin-bottom: 15px;
`;

const TechStackTitle = styled.h4`
  font-size: 1.2rem;
  color: #e6e6e6;
  margin-bottom: 10px;
  text-decoration: underline;
`;

const TechIcons = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 20px;
`;

const ProjectImagesContainer = styled.div`
  margin-top: 20px;
`;

const ScreenshotsLabel = styled.h4`
  font-size: 1.2rem;
  color: #e6e6e6;
  margin-bottom: 10px;
  text-decoration: underline;
`;

const ProjectImages = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

const ProjectImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.4s ease-in-out;

  /* Apply the 3D animation to other project images */
  &:hover {
    transform: scale(1.05) perspective(1000px) rotateY(10deg) rotateX(5deg);

    /* Blue underline animation */
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px;
      background-color: #00ccff;
      transition: width 0.3s ease;
      width: 100%;
    }
  }
`;
// JobLinker-specific images (taller than width)
const JobLinkerImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.4s ease-in-out;

  /* 3D rotation effect */
  &:hover {
    transform: scale(1.05) perspective(1000px) rotateY(10deg) rotateX(5deg);

    /* Blue underline animation */
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px;
      background-color: #00ccff;
      transition: width 0.3s ease;
      width: 100%;
    }
  }
`;

const JobLinkerImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: contain; /* Adjust image to fit within the container */
  transition: transform 0.6s ease-in-out;

  &:hover {
    transform: rotateY(10deg) rotateX(5deg); /* 3D effect on hover */
  }

  @media (max-width: 768px) {
    transform: scale(0.85); /* Slightly scale down the image on mobile */
    object-fit: cover; /* Ensure the image covers the space properly */
    height: 200px; /* Reduce height on mobile for better view */
  }
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 250px;
  object-fit: contain; /* Ensure the image fits without stretching */
  transition: transform 0.6s ease-in-out;

  &:hover {
    transform: rotateY(10deg) rotateX(5deg); /* 3D effect */
  }

  @media (max-width: 768px) {
    transform: scale(0.85); /* Scale down the image on mobile */
    object-fit: cover; /* Ensure the image covers the space properly */
    height: 200px; /* Reduce height on mobile */
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(13, 13, 13, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 90%;
  max-height: 90%;
`;

const ModalImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
`;

const CloseButton = styled.span`
  position: absolute;
  top: -10px;
  right: -10px;
  color: #e6e6e6;
  background-color: #333;
  width: 30px;
  height: 30px;
  font-size: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 30px;
  cursor: pointer;

  &:hover {
    background-color: #555;
  }
`;

const Contact = styled.section`
  padding: 80px 0;
  background-color: #0d0d0d;
  position: relative;
  overflow: hidden;
`;

const ContactWrapper = styled.div`
  margin: 0 auto;
  width: 90%;
  text-align: center;
`;

const ContactTitle = styled.h2`
  font-size: 2.5rem;
  color: #00ccff;
  margin-bottom: 40px;
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  margin-bottom: 50px;
`;

const ContactItem = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #e6e6e6;
  font-size: 1.2rem;

  &:hover {
    color: #00ccff;
  }
`;

const ContactText = styled.span`
  margin-left: 15px;
`;

const ContactForm = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const FormTitle = styled.h3`
  font-size: 1.8rem;
  color: #e6e6e6;
  margin-bottom: 30px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Input = styled.input`
  padding: 15px;
  border-radius: 5px;
  border: none;
  background-color: #1a1a1a;
  color: #e6e6e6;
  font-size: 1rem;

  &:focus {
    outline: none;
    border: 1px solid #00ccff;
  }
`;

const Textarea = styled.textarea`
  padding: 15px;
  border-radius: 5px;
  border: none;
  background-color: #1a1a1a;
  color: #e6e6e6;
  font-size: 1rem;
  resize: vertical;
  min-height: 150px;

  &:focus {
    outline: none;
    border: 1px solid #00ccff;
  }
`;

const SubmitButton = styled.button`
  padding: 15px 30px;
  background-color: #00ccff;
  color: #0d0d0d;
  border: none;
  border-radius: 30px;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #00b3e6;
  }
`;
